import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaFingerprint } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Privacy Policy`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaFingerprint mdxType="FaFingerprint" />} mdxType="ContentPlusDecor">
      <p>{`Protecting your private information is our priority. This Statement of Privacy applies to the `}<em parentName="p">{`TuringTrader.com`}</em>{` website and governs data collection and usage. The `}<em parentName="p">{`TuringTrader.com`}</em>{` website is a news and information site. By using the website, you consent to the data practices described in this statement.`}</p>
      <h2>{`Collection of your Personal Information`}</h2>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` may collect personally identifiable information, such as your name and email address. If you purchase `}<em parentName="p">{`TuringTrader.com`}</em>{`'s products and services, we collect billing and credit card information. Your credit card information is not stored on our servers and is securely transmitted to our sub processors. This information is used to complete the purchase transaction. We may gather additional personal or non-personal information in the future. Information about your computer hardware and software may be automatically collected by `}<em parentName="p">{`TuringTrader.com`}</em>{`. This information can include your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the website. `}<em parentName="p">{`TuringTrader.com`}</em>{` encourages you to review the privacy statements of websites linked to by `}<em parentName="p">{`TuringTrader.com`}</em>{` so that you can understand how those websites collect, use and share your information. `}<em parentName="p">{`TuringTrader.com`}</em>{` is not responsible for the privacy statements or other content on websites outside of this website.`}</p>
      <h2>{`Use of your Personal Information`}</h2>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` collects and uses your personal information to operate its website and deliver the services you have requested. `}<em parentName="p">{`TuringTrader.com`}</em>{` may also use your personally identifiable information to inform you of other products or services available from `}<em parentName="p">{`TuringTrader.com`}</em>{` and its affiliates. `}<em parentName="p">{`TuringTrader.com`}</em>{` may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered. `}<em parentName="p">{`TuringTrader.com`}</em>{` does not sell, rent or lease its customer lists to third parties. `}<em parentName="p">{`TuringTrader.com`}</em>{` may share data with trusted sub processors to help perform statistical analysis, send you email or postal mail, provide customer support, arrange for deliveries, or provide other services. A list of `}<em parentName="p">{`TuringTrader.com`}</em>{`'s sub processors is available on this website and may be reviewed at any time.`}</p>
      <p>{`All such sub processors are prohibited from using your personal information except to provide these services to `}<em parentName="p">{`TuringTrader.com`}</em>{`, and they are required to maintain the confidentiality of your information. `}</p>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on turingtrader.com or the site; (b) protect and defend the rights or property of turingtrader.com; and, (c) act under exigent circumstances to protect the personal safety of users of turingtrader.com or the public.`}</p>
      <h2>{`Use of Cookies`}</h2>
      <p>{`The `}<em parentName="p">{`TuringTrader.com`}</em>{` website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize `}<em parentName="p">{`TuringTrader.com`}</em>{` pages, or register with the `}<em parentName="p">{`TuringTrader.com`}</em>{` site or services, a cookie helps `}<em parentName="p">{`TuringTrader.com`}</em>{` to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing and shipping addresses. When you return to the same `}<em parentName="p">{`TuringTrader.com`}</em>{` website, the information you previously provided can be retrieved, so you can easily use the `}<em parentName="p">{`TuringTrader.com`}</em>{` features that you customized. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the `}<em parentName="p">{`TuringTrader.com`}</em>{` services or websites you visit.`}</p>
      <h2>{`Security of your Personal Information`}</h2>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` secures your personal information from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.`}</p>
      <h2>{`Children Under Thirteen`}</h2>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` does not knowingly collect personally identifiable information from children under the age of eighteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.`}</p>
      <h2>{`Opt-Out & Unsubscribe`}</h2>
      <p>{`We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from `}<em parentName="p">{`TuringTrader.com`}</em>{` by contacting us at `}<a parentName="p" {...{
          "href": "mailto:support@turingtrader.com"
        }}>{`support@turingtrader.com`}</a>{`.`}</p>
      <h2>{`Changes to this Statement`}</h2>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` will occasionally update this Statement of Privacy to reflect company and customer feedback. `}<em parentName="p">{`TuringTrader.com`}</em>{` encourages you to periodically review this Statement to be informed of how `}<em parentName="p">{`TuringTrader.com`}</em>{` is protecting your information.`}</p>
      <h2>{`Contact Information`}</h2>
      <p><em parentName="p">{`TuringTrader.com`}</em>{` welcomes your questions or comments regarding this Statement of Privacy. If you believe that `}<em parentName="p">{`TuringTrader.com`}</em>{` has not adhered to this Statement, please contact us at `}<a parentName="p" {...{
          "href": "mailto:support@turingtrader.com"
        }}>{`support@turingtrader.com`}</a>{`.`}</p>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      